import { reg } from '../../api/list';
import { setToken } from '../../utils/auth';
import { Toast } from 'vant';
export default {
  name: 'reg',

  data() {
    return {
      loginName: '',
      pwd: '',
      pwd2: ''
    };
  },

  created() {
    console.log('init');
  },

  methods: {
    submit() {
      console.log('login-medth');
      reg({
        loginName: this.loginName,
        pwd: this.pwd,
        pwd2: this.pwd2
      }).then(res => {
        console.log(res);
        console.log(res.data);

        if (res.bizCode === 20000) {
          Toast('注册成功');
        }

        setToken(res.data);
      });
    }

  }
};